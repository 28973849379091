import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// css assets
import './assets/vendors/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/vendors/ionicons-npm/css/ionicons.min.css';
import './assets/vendors/linearicons-master/dist/web-font/style.min.css';
import './assets/vendors/pixeden-stroke-7-icon-master/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css';
import './assets/vendors/fancybox/jquery.fancybox.min.css';
import './assets/styles/css/base.min.css';
import './assets/styles/css/style.min.css';
import 'react-toastify/dist/ReactToastify.css';

import Login from './pages/auth/login';
import AdminRoute from './components/AdminRoute';
import UserRoute from './components/UserRoute';
import Admin from './pages/admin';
import User from './pages/users';
import NotFoundPage from './404';

function App() {
  return (
    <Router>
      <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login" component={Login} />
          <AdminRoute path="/admin" component={Admin} />
          <UserRoute path="/user" component={User} />
          <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
