import React, {lazy, Suspense} from 'react';
import {Switch, Route} from 'react-router-dom';
import AdminContextProvider from '../../components/AdminContext';

import Loader from '../../loader';
const load = (Component) => (props) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

const Header = load(lazy(() => import('../../layouts/admin/header')));
const Sidebar = load(lazy(() => import('../../layouts/admin/sidebar')));
const Footer = load(lazy(() => import('../../layouts/admin/footer')));
const Assets = load(lazy(() => import('../../layouts/admin/assets')));
const Modals = load(lazy(() => import('./modals')));
const AdminNotFound = load(lazy(() => import('./404-admin')));
const Home = load(lazy(() => import('./home')));
const Course = load(lazy(() => import('./course')));
const FormCourse = load(lazy(() => import('./form-course')));
const Materi = load(lazy(() => import('./materi')));
const DetailMateri = load(lazy(() => import('./detail-materi')));
const FormMateri = load(lazy(() => import('./form-materi')));
const FormRekamanSesi = load(lazy(() => import('./form-rekaman-sesi')));
const FormEvent = load(lazy(() => import('./form-event')));
const FormTugas = load(lazy(() => import('./form-tugas')));
const FormApprovalTugas = load(lazy(() => import('./form-approval-tugas')));
const FormKehadiran = load(lazy(() => import('./form-kehadiran')));
const Recommend = load(lazy(() => import('./recommend')));
const FormRecommend = load(lazy(() => import('./form-recommend')));
const Tags = load(lazy(() => import('./tags')));
const UserTag = load(lazy(() => import('./user-tag')));

const Admin = () => {
    return (
    <AdminContextProvider>
        <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
            <Header />
            <div className="app-main">
                <Sidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Switch>
                            <Route path="/admin/dashboard" render={() => <Home />} />
                            <Route path="/admin/courses" exact render={() => <Course />} />
                            <Route path="/admin/courses/form-course/:id?" render={() => <FormCourse />} />
                            <Route path="/admin/courses/materi/:id" render={() => <Materi />} />
                            <Route path="/admin/courses/detail-materi/:id/materi/:materi_id" render={() => <DetailMateri />} />
                            <Route path="/admin/courses/form-materi/:id/materi/:materi_id" render={() => <FormMateri />} />
                            <Route path="/admin/courses/form-rekaman-sesi/:id/materi/:materi_id" render={() => <FormRekamanSesi />} />
                            <Route path="/admin/courses/form-event/:id/materi/:materi_id" render={() => <FormEvent />} />
                            <Route path="/admin/courses/form-tugas/:id/materi/:materi_id" render={() => <FormTugas />} />
                            <Route path="/admin/courses/form-approval-tugas/:id/materi/:materi_id" render={() => <FormApprovalTugas />} />
                            <Route path="/admin/courses/form-kehadiran/:id/kehadiran/:materi_id" render={() => <FormKehadiran />} />
                            <Route path="/admin/recommend" exact render={() => <Recommend />} />
                            <Route path="/admin/recommend/form-recommend/:id?" render={() => <FormRecommend />} />
                            <Route path="/admin/tags" render={() => <Tags />} />
                            <Route path="/admin/user-tags" render={() => <UserTag />} />
                            <Route render={() => <AdminNotFound />} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
        <Modals />
        <Assets />
    </AdminContextProvider>
    );
}
 
export default Admin;