import React, {lazy, Suspense} from 'react';
import {Switch, Route} from 'react-router-dom';
import UserContextProvider from '../../components/UserContext';

import Loader from '../../loader';
const load = (Component) => (props) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

const Header = load(lazy(() => import('../../layouts/user/header')));
const Sidebar = load(lazy(() => import('../../layouts/user/sidebar')));
const Footer = load(lazy(() => import('../../layouts/user/footer')));
const Assets = load(lazy(() => import('../../layouts/user/assets')));
const Modals = load(lazy(() => import('./modals')));
const UserNotFound = lazy(() => import('./404-user'));
const Home = lazy(() => import('./home'));
const MyCourses = lazy(() => import('./my-courses'));
const MyCoursesTimeline = lazy(() => import('./my-courses-timeline'));
const Recommend = lazy(() => import('./recommend'));
const Profile = lazy(() => import('./profile'));

const User = () => {
    return (<>
        <UserContextProvider>
            <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
                <Header />
                <div className="app-main">
                    <Sidebar />
                    <div className="app-main__outer">
                        <div className="app-main__inner">
                            <Suspense fallback={<Loader />}>
                                <Switch>
                                    <Route path="/user/dashboard" render={() => <Home />} />
                                    <Route path="/user/my-courses" exact render={() => <MyCourses />} />
                                    <Route path="/user/my-courses/timeline/:id" render={() => <MyCoursesTimeline />} />
                                    <Route path="/user/recommend" render={() => <Recommend />} />
                                    <Route path="/user/profile" render={() => <Profile />} />
                                    <Route render={() => <UserNotFound />} />
                                </Switch>
                            </Suspense>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
            <Modals />
            <Assets />
        </UserContextProvider>
    </>);
}
 
export default User;