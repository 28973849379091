import React, { useState } from 'react';
import { Redirect, NavLink } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';
import jwt_decode from "jwt-decode";

import { setAuthorizationHeader } from "../../configs/axios";

const Login = (props) => {
    const [form, setForm] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState({
        email: '',
        password: '',
    });
    const [isSubmitting, setSubmitting] = useState(false);
    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        setError({
            ...error,
            [e.target.name]: ''
        });
    }

    const validate = () => {
        const newError = { ...error };

        if (!form.email) {
            newError.email = "Email wajib diisi";
        } else if (form.email && !isEmail(form.email)) {
            newError.email = "Email tidak valid";
        } else {
            newError.email = "";
        }

        if (!form.password) {
            newError.password = 'Password wajib diisi';
        } else {
            newError.password = '';
        }

        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findErrors = validate();

        if (Object.values(findErrors).some(err => err !== '')) {
            setError(findErrors);
        } else {
            try {
                setSubmitting(true);

                await axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_HOST}/users/login`,
                    responseType: 'json',
                    headers: {'Accept': 'application/json'},
                    data: {
                        email: form.email,
                        password: form.password,
                    }
                }).then(res => {
                    let data = jwt_decode(res.data.data.token).data;
                    if (data.type === 'admin' && data.platform === 'lms') {
                        setAuthorizationHeader(res.data.data.token);
                        localStorage.setItem(
                            "token",
                            JSON.stringify({
                                ...res.data.data,
                                email: data.email,
                            })
                        );
                        localStorage.setItem(
                            "admin-token",
                            JSON.stringify({
                                ...res.data.data,
                                email: data.email,
                            })
                        );
                        props.history.push('/admin/dashboard');
                    } else if (data.type === 'user') {
                        setAuthorizationHeader(res.data.data.token);
                        localStorage.setItem(
                            "token",
                            JSON.stringify({
                                ...res.data.data,
                                email: data.email,
                            })
                        );
                        localStorage.setItem(
                            "user-token",
                            JSON.stringify({
                                ...res.data.data,
                                email: data.email,
                            })
                        );
                        props.history.push('/user/dashboard');
                    } else {
                        setSubmitting(false);
                        Swal.fire("Oops..", 'Akun ini tidak punya akses ke LMS', "warning");
                    }
                }).catch(err => {
                    setSubmitting(false);
                    Swal.fire("Oops..", 'Email atau Password salah', "error");
                    // console.log(err);
                });
            } catch (e) {
                setSubmitting(false);
            }
        }
    }

    if (localStorage.getItem('admin-token')) {
        return <Redirect to="/admin/dashboard" />
    } else if (localStorage.getItem('user-token')) {
        return <Redirect to="/user/dashboard" />
    }

    return (
    <>
        <div className="app-container app-theme-white body-tabs-shadow">
            <div className="app-container">
                <div className="h-100 bg-plum-plate bg-animation">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <div className="mx-auto app-login-box col-md-8">
                            {/* <div className="app-logo-inverse mx-auto mb-3"></div> */}
                            <center>
                                <img src="/assets-public/images/logo_yhc_lms.svg" className="img-responsive" alt="Logo-YHC" width="250" />
                            </center>
                            <div className="modal-dialog w-100 mx-auto">
                                <div className="modal-content">
                                    <form className="" onSubmit={handleSubmit} noValidate>
                                        <div className="modal-body">
                                            <div className="h5 modal-title text-center">
                                                <h4 className="mt-2">
                                                    <div>Welcome back,</div>
                                                    <span>Please sign in to your account below.</span>
                                                </h4>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <div className="position-relative form-group">
                                                        <input name="email" id="exampleEmail"
                                                            placeholder="Email here..." type="email" className={error.email ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.email} onChange={handleChange} disabled={isSubmitting} />
                                                        <em className={error.email ? `error invalid-feedback` : `d-none`}>{error.email}</em>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="position-relative form-group">
                                                        <input name="password" id="examplePassword"
                                                            placeholder="Password here..." type="password" className={error.password ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.password} onChange={handleChange} disabled={isSubmitting} />
                                                        <em className={error.password ? `error invalid-feedback` : `d-none`}>{error.password}</em>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="position-relative form-check">
                                                <input name="check" id="exampleCheck" type="checkbox" className="form-check-input" />
                                                <label htmlFor="exampleCheck" className="form-check-label">Keep me logged in</label>
                                            </div>
                                        </div>
                                        <div className="modal-footer clearfix">
                                            <div className="float-left">
                                                <NavLink to="#" onClick={e => e.preventDefault()} className="btn-lg btn btn-link">Recover Password</NavLink>
                                            </div>
                                            <div className="float-right">
                                                <button className="btn btn-primary btn-lg" type="submit" disabled={isSubmitting}>
                                                {
                                                    !isSubmitting ?
                                                    <span>Login to Dashboard</span>
                                                    :
                                                    <span><img src="/assets-public/images/ajaxloader.gif" className="img-fluid" style={{width: '18px'}} alt="" /> Logging In</span>
                                                }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="text-center text-white opacity-8 mt-3">Copyright © YHC Learning Management System 2021</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default Login;