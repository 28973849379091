import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function UserRoute({component: Component, ...restProps}) {
    const user = localStorage.getItem('user-token');

    return <Route
        {...restProps}
        render = {props => {
            return user ? <Component {...props} /> : <Redirect to={{pathname: '/login'}} />
        }}
    />
}

export default UserRoute;