import React, { createContext } from 'react';

export const UserContext = createContext();

const UserContextProvider = (props) => {
    const userInfo = {
        user: JSON.parse(localStorage.getItem('user-token'))
    }

    const convertDate = (date) => {
        let tgl = date.split("-");
        let bln;
        switch (tgl[1]) {
            case '01':
                bln = 'January';
                break;
            case '02':
                bln = 'February';
                break;
            case '03':
                bln = 'March';
                break;
            case '04':
                bln = 'April';
                break;
            case '05':
                bln = 'May';
                break;
            case '06':
                bln = 'June';
                break;
            case '07':
                bln = 'July';
                break;
            case '08':
                bln = 'August';
                break;
            case '09':
                bln = 'September';
                break;
            case '10':
                bln = 'October';
                break;
            case '11':
                bln = 'November';
                break;
            default:
                bln = 'Desember';
                break;
        }

        return tgl[2] + ' ' + bln + ' ' + tgl[0];
    }

    const convertShortDate = (date) => {
        let tgl = date.split("-");
        let bln;
        switch (tgl[1]) {
            case '01':
                bln = 'Jan';
                break;
            case '02':
                bln = 'Feb';
                break;
            case '03':
                bln = 'Mar';
                break;
            case '04':
                bln = 'Apr';
                break;
            case '05':
                bln = 'May';
                break;
            case '06':
                bln = 'Jun';
                break;
            case '07':
                bln = 'Jul';
                break;
            case '08':
                bln = 'Aug';
                break;
            case '09':
                bln = 'Sep';
                break;
            case '10':
                bln = 'Oct';
                break;
            case '11':
                bln = 'Nov';
                break;
            default:
                bln = 'Des';
                break;
        }

        return tgl[2] + ' ' + bln + ' ' + tgl[0];
    }

    return (
        <UserContext.Provider value={{ ...userInfo, convertDate, convertShortDate }}>
            {props.children}
        </UserContext.Provider>
    );
}
 
export default UserContextProvider;